/*Buttons*/
.btn{transition:$globalTransitions;}
.btn:hover{opacity:0.9}
.btn-full{display:block;}

.btn-xxs, .btn-xs, .btn-s, .btn-sm, .btn-m, .btn-l{font-size:12px!important;}

.btn-xxs{padding:$btn-xxs!important;}
.btn-xs{padding:$btn-xs!important;}
.btn-s{padding:$btn-s!important;}
.btn-sm{padding:$btn-sm!important;}
.btn-m{padding:$btn-m!important;}
.btn-l{padding:$btn-l!important;}
.btn-xl{padding:$btn-xl!important; font-size:15px!important;}
.btn-xxl{padding:$btn-xxl!important; font-size:17px!important;}
.btn-uppercase{text-transform:uppercase;}
.btn-center-xs{width:$btn-center-xs; margin:0 auto; display:block;}
.btn-center-s{width:$btn-center-s; margin:0 auto; display:block;}
.btn-center-m{width:$btn-center-m; margin:0 auto; display:block;}
.btn-center-l{width:$btn-center-l; margin:0 auto; display:block;}
.btn-center-xl{width:$btn-center-xl; margin:0 auto; display:block;}
.btn.bg-highlight{color:#FFF;}
.btn-3d{transition: all 150ms ease; border:none; border-bottom:solid 4px;}
.btn-3d:hover{border-width:2px!important; }
.btn:focus{outline:none!important;}
.btn-margins{margin-left:17px; margin-right:17px; margin-bottom:$globalMargin;}
.under-slider-btn{z-index:50; position:relative; margin-top:-20px;}
.under-btn{z-index:50; position:relative; margin-top:-20px!important;}
.btn-icon:hover{color:#FFF;}
.btn-icon{padding-left:50px!important; overflow:hidden; position:relative;}
.btn-icon i{
    position:absolute; 
    left:0px; 
    top:0px;
    line-height:43px;
    width:40px; 
    height:100%; 
    background-color:rgba(0,0,0,0.1);
}

button{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

//.btn:not(.color-black):not(.accordion-btn):hover{
//    color:#FFF!important;
//}