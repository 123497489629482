/*Pricing*/
.pricing-1 {
	max-width: 280px;
	margin: 0 auto $globalMargin auto;
	border: solid 1px rgba(0,0,0,0.05);
	padding: 20px 0px 30px 0px;
	* {
		list-style: none;
	}
	.pricing-icon {
		font-size: 50px;
	}
	.pricing-title {
		font-size: 32px;
		padding: 20px 0px 0px 0px;
	}
	.pricing-value {
		font-size: 40px;
		font-weight: 300;
		padding: 20px 0px 0px 0px;
		sup {
			font-size: 12px;
			top: -18px;
			&:first-child {
				padding-right: 5px;
				top: -13px;
				font-size: 20px;
			}
		}
	}
	.pricing-subtitle {
		opacity: 0.6;
		font-size: 11px;
		line-height: 18px;
		font-weight: 300;
		padding: 0px 0px 10px 0px;
	}
	.pricing-list {
		padding: 0px;
		li {
			line-height: 40px;
			border-bottom: solid 1px rgba(0,0,0,0.05);
			font-size: 14px;
			font-weight: 500;
			&:last-child {
				border-bottom: none;
				margin-bottom: -20px;
			}
		}
	}
}
.pricing-2 {
	max-width: 280px;
	margin: 0 auto $globalMargin auto;
	border: solid 1px rgba(0,0,0,0.05);
	padding: 0px 0px 10px 0px;
	overflow: hidden;
	* {
		list-style: none;
	}
	.pricing-icon {
		font-size: 50px;
		padding: 10px 0px 10px 0px;
	}
	.pricing-title {
		font-size: 25px;
		padding: 15px 0px 15px 0px;
		margin: -10px 0px 20px 0px;
	}
	.pricing-overtitle {
		font-size: 25px;
		padding: 15px 0px 15px 0px;
		margin: -10px 0px 0px 0px;
	}
	.pricing-value {
		font-size: 43px;
		padding: 15px 0px 0px 0px;
		sup {
			font-size: 12px;
			top: -18px;
			&:first-child {
				padding-right: 5px;
				top: -13px;
				font-size: 20px;
			}
		}
	}
	.pricing-subtitle {
		font-size: 11px;
		line-height: 18px;
		font-weight: 400;
		padding: 0px 0px 0px 0px;
	}
	.pricing-list {
		padding: 0px;
		li {
			line-height: 40px;
			border-bottom: solid 1px rgba(0,0,0,0.04);
			font-size: 14px;
			font-weight: 500;
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
.pricing-3 {
	max-width: 280px;
	margin: 0 auto $globalMargin auto;
	border: solid 1px rgba(0,0,0,0.05);
	padding: 0px 0px 10px 0px;
	overflow: hidden;
	* {
		list-style: none;
	}
	.btn {
		max-width: 90%;
		margin: -10px auto 10px auto;
	}
	.pricing-icon {
		font-size: 50px;
	}
	.pricing-title {
		font-size: 28px;
		padding: 30px 0px 0px 20px;
	}
	.pricing-value {
		text-align: center;
		font-size: 40px;
		font-weight: 300;
		padding: 20px 0px 20px 0px;
		sup {
			font-size: 12px;
			top: -18px;
			&:first-child {
				padding-right: 5px;
				top: -13px;
				font-size: 20px;
			}
		}
	}
	.pricing-subtitle {
		opacity: 0.6;
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
		padding: 0px 0px 30px 20px;
		border-bottom: solid 1px rgba(0,0,0,0.1);
	}
	.pricing-list {
		padding: 0px;
		i {
			padding-right: 20px;
			width: 30px;
		}
		li {
			line-height: 50px;
			border-bottom: solid 1px rgba(0,0,0,0.05);
			font-size: 14px;
			font-weight: 500;
			padding: 0px 0px 0px 20px;
		}
	}
}
.pricing-4 {
	max-width: 280px;
	margin: 0 auto $globalMargin auto;
	border: solid 1px rgba(0,0,0,0.05);
	padding: 0px 0px 10px 0px;
	overflow: hidden;
	* {
		list-style: none;
	}
	.pricing-title {
		font-size: 18px;
		padding: 15px 0px 15px 0px;
		margin: 0px 0px 0px 0px;
	}
	.pricing-value {
		font-size: 43px;
		padding: 30px 0px 20px 0px;
		sup {
			font-size: 12px;
			top: -18px;
			&:first-child {
				padding-right: 5px;
				top: -13px;
				font-size: 20px;
			}
		}
	}
	.pricing-subtitle {
		font-size: 11px;
		line-height: 18px;
		font-weight: 400;
		padding: 0px 0px 20px 0px;
		margin-top: -10px;
	}
	.pricing-list {
		padding: 0px;
		margin-bottom: 20px;
		li {
			line-height: 40px;
			border-bottom: solid 1px rgba(0,0,0,0.05);
			font-size: 14px;
			font-weight: 500;
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
.pricing-single {
	max-width: 280px;
	margin: 0 auto;
}
.pricing-double-1 {
	width: 48%;
	float: left;
	margin-right: 4%;
}
.pricing-double-2 {
	width: 48%;
	float: left;
}
.pricing-list {
	list-style: none !important;
	ul {
		list-style: none !important;
	}
	li {
		list-style: none !important;
	}
}
