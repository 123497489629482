/*Notifications*/
.notification {
	position: fixed;
	top: 10px;
	left: 10px;
	right: 10px;
	z-index: 101;
	border-radius: 10px;
	opacity: 0.98;
	transform: translateY(-120%);
	transition: all 250ms ease;
	.notification-icon {
		padding: 5px 5px;
		display: block;
		background-color: $gray-800;
		i {
			&:first-child {
				margin: 0px 5px 0px 10px;
				font-size: 10px;
			}
			&:last-child {
				position: absolute;
				right: 0px;
				line-height: 30px;
				width: 40px;
				text-align: center;
			}
		}
		em {
			font-size: 11px;
			text-transform: uppercase;
			font-style: normal;
		}
	}
	h1 {
		margin: 10px 15px -7px 15px;
	}
	p {
		line-height: 15px;
		margin: 5px 15px 18px 15px;
		color: $color_white;
	}
}
.notification.show {
	transform: translateY(0%) !important;
}
.is-ios.is-on-homescreen .notification.show {
	transform: translateY(30px) !important;
}
.notification-android {
	left: 5px;
	right: 5px;
	top: 5px;
	border-radius: 3px;
	i {
		position: absolute;
		top: 50%;
		transform: translateY(-55%);
		width: 35px;
		margin-left: 15px;
		line-height: 35px;
		border-radius: 35px;
		text-align: center;
		font-size: 13px;
	}
	h1 {
		padding-left: 55px;
	}
	p {
		padding-right: 60px;
		padding-left: 55px;
		font-size: 12px;
	}
	strong {
		position: absolute;
		right: 20px;
		top: 20px;
		font-size: 10px;
		font-weight: 400;
		font-style: normal;
	}
	.notification-buttons {
		width: 100%;
		margin-left: 70px;
		padding-top: 5px;
		border-top: solid 1px rgba(255,255,255,0.1);
		i {
			opacity: 0.6;
			font-size: 11px;
			position: relative;
			float: left;
			margin: 0px 10px 0px 0px;
			width: auto;
			transform: translateY(-22px);
		}
	}
	a {
		font-size: 11px;
		color: $color_white;
		text-transform: uppercase;
		text-align: left;
		font-weight: 500;
	}
}
.notification-material {
	left: 0px;
	right: 0px;
	top: 0px;
	border-radius: 0px;
	strong {
		font-size: 10px;
		font-weight: 400;
	}
	em {
		text-transform: none !important;
	}
	.notification-icon {
		background-color: $color_transparent !important;
		margin-bottom: -15px;
	}
	img {
		position: absolute;
		width: 50px;
		border-radius: 50px;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
	p {
		padding-right: 50px;
	}
}
