/* Generating Color Pack*/

$colorHighlightLight: #AC92EC;
$colorHighlightDark: #967ADC;

.color-highlight{color:$colorHighlightDark!important;}
.bg-highlight{background-color:$colorHighlightDark!important; color:#FFF!important;}
.border-highlight{border-color:$colorHighlightDark!important;}
.gradient-highlight{background-image:linear-gradient(to bottom, $colorHighlightLight, $colorHighlightDark)!important;}

//.form-control:focus,
//.form-select:focus{border-color:$colorHighlightDark!important;}
.footer-bar-2 .active-nav,
.footer-bar-5 strong,
.footer-bar-4 strong,
.splide__pagination__page.is-active{background-color:$colorHighlightDark!important} 
.footer-bar-1 .active-nav i,
.footer-bar-1 .active-nav span,
.footer-bar-3 .active-nav i{color:$colorHighlightDark!important} 
.form-floating-over > .form-control:focus ~ label{color:$colorHighlightDark;}
.form-floating-over > .form-control:not(:placeholder-shown) ~ label{color:$colorHighlightDark;}
.form-floating-over > .form-select ~ label{color:$colorHighlightDark;}
.color-highlight{color:$colorHighlightDark;}
.bg-highlight{background-color:$colorHighlightDark; color:#FFF!important;}
.interest-check input:checked ~ label{color:#FFF!important; background-color:$colorHighlightDark!important;}
