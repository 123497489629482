
@import './assets/scss/bootstrap.scss';
@import './assets/scss/style.scss';

@import './assets/scss/highlights/highlight_magenta.scss';

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 600;
  font-family: Roboto, sans-serif !important;
}

.card-style{
  border-radius: 30px;
}

i{
  font-style: normal;
}


@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}