/*-Footer Menu-*/
#footer-bar {
	position: fixed;
	bottom: 0px;
	left: 0px;
	right: 0px;
	z-index: 98;
	background-color: rgba(249,249,249,.98);
	box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.06);
	min-height: 60px;
    height: calc(62px + (constant(safe-area-inset-bottom))*1.1);
    height: calc(62px + (env(safe-area-inset-bottom))*1.1);
	display: flex;
	text-align: center;
	a {
		color: $color-dark;
		padding-top: 12px;
		position: relative;
		flex: 1 1 auto;
		span {
			position: relative;
			z-index: 2;
			display: block;
			font-size: 10px;
			font-weight: 500;
			margin-top: -6px;
			opacity: 0.7;
			font-family: $font-main-family !important;
		}
		i {
			font-size: 18px;
			position: relative;
			z-index: 2;
		}
	}
	.badge {
		font-style: normal;
		z-index: 5;
		top: 0px;
		position: absolute;
		margin-left: 3px;
		color: $color-white !important;
		width: 18px;
		text-align: center;
		line-height: 18px;
		padding: 0px;
		padding-left: 0px !important;
		border-radius: 18px;
		margin-top: 7px;
		font-size: 11px;
	}
}
.footer-bar-2 {
	.active-nav {
		strong {
			position: absolute;
			width: 80px;
			left: 50%;
			transform: translateX(-50%);
			top: 0px;
			bottom: 0px;
		}
		color: $color-white !important;
	}
}
.footer-bar-4 {
	.active-nav {
		strong {
			position: absolute;
			width: 47px;
			height: 47px;
			border-radius: 60px;
			left: 50%;
			top: 30px;
			transform: translate(-50%, -50%);
			bottom: 0px;
		}
		color: $color-white !important;
	}
	span {
		display: none !important;
	}
	i {
		padding-top: 10px;
	}
}
.footer-bar-5 {
	.active-nav {
		strong {
			position: absolute;
			width: 50px;
			height: 2px;
			border-radius: 60px;
			left: 50%;
			top: 0px;
			transform: translateX(-50%);
		}
	}
}
.footer-bar-3 {
	span {
		display: none !important;
	}
	.active-nav {
		span {
			display: block !important;
		}
		padding-top: 11px !important;
	}
	a {
		padding-top: 18px !important;
	}
}
.footer-menu-hidden {
	transition: all 100ms ease;
	transform: translateY(100%) !important;
}
.footer-bar-white {
	* {
		color: $color-white;
	}
}
#footer-bar.position-relative {
	z-index: 2 !important;
}
.is-ios {
	#footer-bar {
		height: calc(65px + (constant(safe-area-inset-bottom))*1.1);
		height: calc(65px + (env(safe-area-inset-bottom))*1.1);
	}
}
.is-not-ios {
	.footer-menu-clear {
		height: 70px;
		display: block;
	}
	.footer {
		padding-bottom: 0px;
	}
	#footer-menu {
		a {
			i {
				padding-top: 13px;
			}
			span {
				opacity: 0.6;
			}
		}
	}
}

/*Sticky 4.1 Scrolling Footer Bar*/
.footer-bar-scroll {
	display: block !important;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	margin: 0px;
	padding: 0px;
	a {
		display: inline-block;
		width: 19.5%;
	}
}
.footer-bar-scroll.footer-bar-4 {
	.active-nav {
		strong {
			margin-top: 1px;
		}
	}
	.badge {
		margin-left: -8px !important;
		margin-top: 14px !important;
	}
}
.footer-bar-scroll.footer-bar-3 {
	.badge {
		margin-left: -7px !important;
		margin-top: 0px !important;
	}
	i {
		transform: translateY(-10px);
	}
	.active-nav {
		i {
			transform: translateY(0px);
		}
	}
}

