/*Menus & Action Sheets & Modals*/

$theme-dark-menu-hider: rgba(0,0,0,0.7);
$theme-light-menu-hider: rgba(0,0,0,0.35);

#menu-settings{overflow:hidden;}

/*--Menu System---*/
.menu-title {
	h1 {
		font-size: 23px;
		font-weight: 700;
		padding: 20px 0px 3px 20px;
	}
	p {
		font-size: 11px;
		margin: -15px 0px 10px 20px;
		padding: 0px 0px 0px 0px;
	}
	a {
		font-size: 20px;
		color: $red;
		position: absolute;
		text-align: center;
		width: 55px;
		line-height: 85px;
		right: 0px;
		top: 0px;
	}
}
.menu-bg {
	background-color: $theme-light-background;
}
.menu-hider {
	position: fixed;
	top: -100px;
	bottom: -100px;
	left: 0px;
	right: 0px;
	background-color: $theme-light-menu-hider;
	z-index: 100;
	pointer-events: none;
	opacity: 0;
	transition: $globalTransitions;
}
.menu {
	display: none;
	position: fixed;
	background-color: $color-white;
	z-index: 101;
	overflow: scroll;
	transition: $globalTransitions;
	-webkit-overflow-scrolling: touch;
	.content {
		margin-bottom: 0px !important;
	}
}
.menu-box-left {
	left: 0px;
	top: 0px !important;
	top: constant(safe-area-inset-top) !important;
	top: env(safe-area-inset-top) !important;
	width: 250px;
	height: 100%;
	bottom: 0px;
	box-shadow: 0 15px 25px 1px rgba(0,0,0,0.1);
	transform: translate3d(-100%,0,0);
}
.menu-box-right {
	right: 0px;
	transform: translateX(100%);
	top: 0px !important;
	top: constant(safe-area-inset-top) !important;
	top: env(safe-area-inset-top) !important;
	height: 100%;
	bottom: 0px;
	box-shadow: 0 15px 25px 1px rgba(0,0,0,0.1);
	width: 250px;
}
.menu-box-top {
	transform: translate3d(0, -115%, 0);
	top: 0px;
	left: 0px;
	right: 0px;
	height: auto;
}
.menu-box-bottom {
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: auto;
	transform: translate3d(0, 110%, 0);
}
.menu-box-modal {
	top: 50%;
	left: 50%;
    width:330px;
    max-height:480px;
	transform: translate(-50%, -50%) scale(0, 0);
	opacity: 0;
	pointer-events: none;
	transition: $globalTransitions;
}
.menu-box-modal-full{
    max-height:100vh;
    width:100vw;
    height:100vh;
}
.menu-box-detached, .menu-box-modal{
    border-radius:15px;
}
.menu-box-top.menu-box-detached {
	top: 10px;
	left: 10px;
	right: 10px;
}
.menu-box-bottom.menu-box-detached {
	bottom: 20px;
	left: 10px;
	right: 10px;
}
.menu-box-left.menu-active {transform: translate3d(0%, 0, 0) !important;}
.menu-box-right.menu-active {transform: translate3d(0%, 0, 0) !important;}
.menu-box-top.menu-active {transform: translate3d(0%, 0, 0) !important;}
.is-on-homescreen {
	.menu-box-top.menu-active {
		transform: translate3d(0%, 40px, 0) !important;
	}
}
.menu-box-bottom.menu-active {
	transform: translate3d(0%, 0, 0) !important;
}
.menu-box-modal.menu-active {
	transition: $globalTransitions;
	pointer-events: all !important;
	opacity: 1 !important;
	transform: translate(-50%, -50%) scale(1, 1);
}
.menu-box-modal.menu-box-modal-full{
    max-height:100%;
    width:100%;
    height:100%;
    left:0px;
    right:0px;
    top:0px;
    bottom:0px;
    transform:translate(0%,0%);
}

.menu-hider.menu-active {
	transition: $globalTransitions;
	opacity: 1;
	pointer-events: all;
}
.menu-hider.menu-active.menu-active-clear {
	background-color: $color-transparent !important;
}
.menu-box-left[data-menu-effect="menu-reveal"] {
	opacity: 0;
	pointer-events: none;
	transform: translate(0px) !important;
	z-index: 96;
}
.menu-box-right[data-menu-effect="menu-reveal"] {
	opacity: 0;
	pointer-events: none;
	transform: translate(0px) !important;
	z-index: 96;
}
.menu-active.menu {
	opacity: 1 !important;
	transition: $globalTransitions;
	pointer-events: all !important;
}
/*Menu Update*/
#menu-update {
	background-color: $color-white;
	position: fixed;
	z-index: 9999;
	width: 310px;
	height: 430px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	pointer-events: none;
	border-radius: 10px;
	transition: $globalTransitions;
}
.menu-active {
	opacity: 1 !important;
}
.menu-active-no-click {
	opacity: 1;
	background-color: $theme-dark-menu-hider;
	pointer-events: all !important;
}
#menu-install-pwa-ios {
	overflow: visible !important;
}
.fa-ios-arrow {
	transform: translateY(4px);
}
.theme-light {
	.fa-ios-arrow {
		color: $color-white !important;
	}
}

//Sidebar Enhancements - Sticky 5.4
//General Settings
.menu-box-left.menu-box-detached{
	left:10px;
	top:10px!important;
	bottom:10px;
	height:calc(100% - 20px);
	transform:translateX(-120%)
}

.menu-box-right.menu-box-detached{
	right:10px;
	top:10px!important;
	bottom:10px;
	height:calc(100% - 20px);
	transform:translateX(120%)
}

.menu-box-left .ios-switch,
.menu-box-left .android-switch{
	transform:scale(0.7, 0.7) translateY(-6px) translateX(8px);
	margin-right:-35px;
}


//Sidebar Design
.sidebar-content{
	min-height: 93%;
	display: flex;
	flex-direction: column;
}

.badge-small{
	width:18px;
	height:18px;
	line-height:18px;
	text-align:center;
	padding:0px!important;
}

.menu-sidebar .list-custom-small{
	padding-top:5px;
}
.menu-sidebar .list-custom-small a{
	border-bottom:0px!important;
}

.theme-light .menu-box-left{
	background-color:#f7f7f7;
}

.menu-sidebar .list-custom-small i:first-child{
	width:27px!important;
	height:27px!important;
	line-height:27px!important;
}

.menu-sidebar .list-custom-small img{
	margin-right:3px;
	transform:translateX(-2px);
}

